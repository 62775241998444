import React, {useEffect, useState} from "react";
import BodyComponents from "../components/BodyComponents";
import EnJson from './translations/en.json';

const EnglishPage = (props) => {
    const [isMobile, setIsMobile] = useState(false);
    const [tabletDown, setTabletDown] = useState(false);
    const [isDesktopDown, setIsDesktopDown] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [mobileUp, setMobileUp] = useState(false);
    const [mobileDown, setMobileDown] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < 480);
        setMobileUp(window.innerWidth > 768);
        setMobileDown(window.innerWidth < 768);
        setTabletDown(window.innerWidth < 1024);
        setIsDesktopDown(window.innerWidth < 1200);
        setIsDesktop(window.innerWidth >= 1200);

        window.addEventListener("resize", () => {
            setTimeout(() => {
                setIsMobile(window.innerWidth < 480);
                setMobileUp(window.innerWidth > 768);
                setMobileDown(window.innerWidth < 768);
                setTabletDown(window.innerWidth < 1024);
                setIsDesktopDown(window.innerWidth < 1200);
                setIsDesktop(window.innerWidth >= 1200);
            }, 1);
        });
    }, []);

    return (
        <BodyComponents
            location={props.location}
            dataTexts={EnJson}
            isMobile={isMobile}
            tabletDown={tabletDown}
            isDesktopDown={isDesktopDown}
            isDesktop={isDesktop}
            mobileUp={mobileUp}
            mobileDown={mobileDown}
        />
    )
}

export default EnglishPage;